// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import Button from '../Button/Button';
import styles from './TextModule.module.scss';

const TextModule = ({
    alignText = false,
    theme = '',
    title = '',
    html = '',
    buttons = [],
}) => {
    const classes = classNames(
        styles['TextModule'],
        styles['TextModule--' + ucFirst(theme)],
        {
            [styles['TextModule--FullHeading']]: !alignText,
        }
    );

    return (
        <div className={classes}>
            <div className={styles['TextModule__Container']}>
                {!alignText && !!title && (
                    <h2 className={styles['TextModule__Title']}>{title}</h2>
                )}
                <div className={styles['TextModule__Grid']}>
                    {alignText && !!title && (
                        <h2 className={styles['TextModule__Title']}>{title}</h2>
                    )}
                    <div className={styles['TextModule__Content']}>
                        <div
                            className={styles['TextModule__Richtext']}
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                        {buttons.length > 0 && (
                            <div className={styles['TextModule__Buttons']}>
                                {buttons.map((item, index) => (
                                    <Button
                                        key={index}
                                        {...item}
                                        modifiers={['quaternary']}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

TextModule.propTypes = {};

export default TextModule;
